import React, {useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import { sendWithAxios } from './functions/api';
import {
    PageSection,
    PageSectionVariants,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Gallery,
    GalleryItem,
    Title,
    Breadcrumb,
    BreadcrumbItem,

    Form,
    FormGroup,
    TextInput,
    TextArea,
    ActionGroup,
    Button,
    Alert,

    Grid,
    GridItem, PanelMain, PanelMainBody, Panel, FormSelect, FormSelectOption

} from "@patternfly/react-core";


import '@patternfly/patternfly/patternfly.min.css';
import {Link} from "react-router-dom";
import {Table, Tbody, Td, Tr} from "@patternfly/react-table";
import {PlusCircleIcon} from "@patternfly/react-icons";

const SampleDetailPage = () => {
    const navigate = useNavigate();

    let { id, subId,layerId, sampleId} = useParams();
    let itemId = Number(id);
    let itemSubId = Number(subId);
    let itemLayerId = Number(layerId);
    let itemSampleId = Number(sampleId);

    const [data, setData] = useState({
        'GEO_OBJECT' : {},
        'BOREHOLE' : {},
        'LAYER' : {},
        'SAMPLE' : {},
        'LAYER_TYPES' : []
    });


    const [isEditMode, setIsEditMode] = useState(false);

    const [editFromData, setEditFromData] = useState({
        'UF_DEPTH_START' : '',
        'UF_DEPTH_END' : '',
        'UF_TYPE' : ''
    });



    const onSampleFormSubmit = (e) => {
        e.preventDefault();


        sendWithAxios('/geoobjects/' + id + '/borehole/' + subId + '/layer/' + layerId + '/sample/' + sampleId, editFromData, 'PUT')
            .then(result => {



                if (itemSampleId === 0) {

                    if (result.SAMPLE && result.SAMPLE.ID) {
                        navigate('/app/geoobjects/' + id + '/borehole/' + subId + '/layer/' + layerId + '/sample/' + result.SAMPLE.ID)
                    }

                } else {


                    setEditFromData({
                        'UF_DEPTH_START' : result.SAMPLE.UF_DEPTH_START,
                        'UF_DEPTH_END' : result.SAMPLE.UF_DEPTH_END,
                        'UF_TYPE' : result.SAMPLE.UF_TYPE,
                    });

                    setData(result);
                }

            });

    };

    const onSampleFormInputChange = e => {
        setEditFromData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };


    useEffect(() => {

        if (itemSampleId === 0) {
            setIsEditMode(true);
        }

        sendWithAxios('/geoobjects/' + id + '/borehole/' + subId + '/layer/' + layerId + '/sample/' + sampleId, {})
            .then(result => {

                setEditFromData({
                    'UF_DEPTH_START' : result.SAMPLE.UF_DEPTH_START,
                    'UF_DEPTH_END' : result.SAMPLE.UF_DEPTH_END,
                    'UF_TYPE' : result.SAMPLE.UF_TYPE,
                });

                setData(result);
            });


    }, [id, subId, layerId, sampleId]);


    return (
        <>

            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}/layer/${itemLayerId}`}>
                            <Button variant="primary">Назад</Button>
                        </Link>
                    </PanelMainBody>
                </PanelMain>
            </Panel>


            <PageSection variant={PageSectionVariants.light}>
                <Grid>
                    <GridItem sm={12} md={8} lg={8}>
                        <Title headingLevel="h1">{ itemSampleId === 0 ? "Новый образец" : `Образец: ${data.LAYER.UF_DEPTH_START} - ${data.LAYER.UF_DEPTH_END}`}</Title>
                        <br/>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/`}>Объекты</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/${itemId}`}>{data.GEO_OBJECT.UF_NAME}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}`}>{data.BOREHOLE.UF_NAME}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}/layer/${itemLayerId}`}>
                                    Слой: {data.LAYER.UF_DEPTH_START} - {data.LAYER.UF_DEPTH_END}
                                </Link>
                            </BreadcrumbItem>

                            {
                                itemSampleId === 0 ? (
                                    <BreadcrumbItem>Новый образец</BreadcrumbItem>
                                ) : (
                                    <BreadcrumbItem>Образец: {data.LAYER.UF_DEPTH_START} - {data.LAYER.UF_DEPTH_END}</BreadcrumbItem>
                                )
                            }
                        </Breadcrumb>
                        <br/><br/>

                        {

                            isEditMode === true ? (

                                <>

                                    <Card ouiaId="BasicCard">
                                        <CardTitle>{itemSampleId === 0 ? 'Внести образец' : 'Редактировать образец'}</CardTitle>
                                        <CardBody>
                                            <Form onSubmit={onSampleFormSubmit} isHorizontal>


                                                <FormGroup label="Глубина начала" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_DEPTH_START}
                                                        onChange={onSampleFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_DEPTH_START'
                                                        isRequired
                                                        name="UF_DEPTH_START"/>
                                                </FormGroup>


                                                <FormGroup label="Глубина конца" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_DEPTH_END}
                                                        onChange={onSampleFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_DEPTH_END'
                                                        isRequired
                                                        name="UF_DEPTH_END"/>
                                                </FormGroup>


                                                <FormGroup label="Тип" isRequired>
                                                    <FormSelect
                                                        value={editFromData.UF_TYPE}
                                                        onChange={onSampleFormInputChange}
                                                        name="UF_TYPE"
                                                        aria-label="Тип"
                                                    >
                                                        <FormSelectOption key="0" value="0" label="(Выбрать)"/>
                                                        {data.LAYER_TYPES.map((option) => (
                                                            <FormSelectOption key={option.ID} value={option.ID}
                                                                              label={option.LABEL}/>
                                                        ))}
                                                    </FormSelect>
                                                </FormGroup>


                                                <ActionGroup>
                                                    <Button
                                                        type="submit"
                                                        variant="primary">{itemSampleId === 0 ? 'Внести' : 'Обновить'}</Button>

                                                    {
                                                        itemSampleId === 0 ? '' : (
                                                            <Button variant="secondary"
                                                                    onClick={() => setIsEditMode(false)}>
                                                                Отменить
                                                            </Button>
                                                        )
                                                    }

                                                </ActionGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>

                                </>


                            ) : (

                                <>

                                    <Button onClick={() => setIsEditMode(true)}>
                                        Редактировать
                                    </Button>
                                    <br/><br/>
                                    <Table variant="compact">
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <b>Глубина начала</b>
                                                </Td>
                                                <Td>
                                                    {data.SAMPLE.UF_DEPTH_START}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Глубина конца</b>
                                                </Td>
                                                <Td>
                                                    {data.SAMPLE.UF_DEPTH_END}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Тип</b>
                                                </Td>
                                                <Td>
                                                    {data.SAMPLE.UF_TYPE}
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>

                                </>

                            )
                        }

                    </GridItem>
                </Grid>
            </PageSection>


        </>
    );


};

export default SampleDetailPage;