import React, {useEffect, useState } from 'react';


import { sendWithAxios } from './functions/api';
import {
    PageSection,
    PageSectionVariants,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Gallery,
    GalleryItem,

    Form,
    FormGroup,
    TextInput,
    ActionGroup,
    Button,
    Alert,

    Grid,
    GridItem, PanelMain, PanelMainBody, Panel, Title, Breadcrumb, BreadcrumbItem

} from "@patternfly/react-core";


import '@patternfly/patternfly/patternfly.min.css';
import {Link} from "react-router-dom";

const GeoObjectsPage = () => {

    const [data, setData] = useState({
        items : []
    });


    useEffect(() => {

        sendWithAxios('/geoobjects/', {})
            .then(result => {
                setData(result);
            });


    }, []);

    return (
        <>
            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Link to={`/app/geoobjects/0`}>
                            <Button variant="primary">Создать новый объект</Button>
                        </Link>
                    </PanelMainBody>
                </PanelMain>
            </Panel>

            <PageSection variant={PageSectionVariants.light}>
                <Grid>
                    <GridItem sm={12} md={8} lg={8}>
                        <Title headingLevel="h1">Объекты</Title>
                    </GridItem>
                </Grid>
            </PageSection>

            <PageSection variant={PageSectionVariants.light}>
                <Gallery hasGutter>
                    {data.items.map((listItem) => (
                        <GalleryItem key={listItem.ID}>
                            <Card isFullHeight="true" ouiaId="BasicCard">
                                <CardTitle>{listItem.UF_NAME}</CardTitle>
                                <CardBody>
                                    {listItem.UF_ADDRESS}
                                </CardBody>
                                <CardFooter>
                                    <Link to={`/app/geoobjects/${listItem.ID}`}>
                                        <Button variant="primary">Подробнее</Button>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </GalleryItem>
                    ))}
                </Gallery>
            </PageSection>
        </>
    );
};

export default GeoObjectsPage;