import React, { useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import {Link, Outlet, useNavigate} from 'react-router-dom';


import AuthForm from "./AuthForm";



import {
    Page,
    Masthead,
    MastheadToggle,
    MastheadMain,
    MastheadBrand,
    MastheadContent,
    PageSidebar,
    PageSidebarBody,
    PageSection,
    PageSectionVariants,
    PageToggleButton,
    Toolbar,
    ToolbarContent,
    ToolbarItem,

    Menu,
    MenuContent,
    MenuList,
    MenuItem,

    Dropdown,
    DropdownItem,

    Nav,
    NavList,
    NavItem,

    ToolbarGroup,
    Button,
    ButtonVariant


} from '@patternfly/react-core';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import '@patternfly/patternfly/patternfly.min.css';




const HomePage = () => {
    const { isAuthenticated, setIsAuthenticated } = useContext(UserContext);
    const navigate = useNavigate();



    const logout = () => {
        window.localStorage.removeItem('userJwtToken');
        setIsAuthenticated(false);
        navigate('/');
    };


    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);



    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const onSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const header = (
        <Masthead>
            <MastheadToggle>
                <PageToggleButton
                    variant="plain"
                    aria-label="Global navigation"
                    isSidebarOpen={isSidebarOpen}
                    onSidebarToggle={onSidebarToggle}
                >
                    <BarsIcon />
                </PageToggleButton>
            </MastheadToggle>
            <MastheadMain>
                <MastheadBrand>
                    Logo
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarGroup variant='button-group'>
                            <ToolbarItem>
                                <Button onClick={logout}>Выйти</Button>
                            </ToolbarItem>
                        </ToolbarGroup>
                    </ToolbarContent>
                </Toolbar>
            </MastheadContent>
        </Masthead>
    );

    const sidebar = (
        <PageSidebar isSidebarOpen={isSidebarOpen}>
            <PageSidebarBody>
                <Nav>
                    <NavList>
                        <NavItem>
                            <Link to='/app/dashboard'>Dashboard</Link>
                        </NavItem>
                        <NavItem>
                            <Link to='/app/teams'>Команды</Link>
                        </NavItem>
                        <NavItem>
                            <Link to='/app/geoobjects'>Объекты</Link>
                        </NavItem>
                    </NavList>
                </Nav>
            </PageSidebarBody>
        </PageSidebar>
    );

    return (
        <Page header={isAuthenticated ? header : null} sidebar={isAuthenticated ? sidebar : null}>
            {!isAuthenticated ? <AuthForm /> : ''}
            <Outlet></Outlet>
        </Page>
    );
};

export default HomePage;