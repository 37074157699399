import { useState, useEffect } from 'react';

const useLocalStorage = (key, initialVal) => {
    const [value, setValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialVal;
        } catch (err) {
            console.log(err);
            return initialVal;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.error(err);
        }
    }, [value, key]);

    return [value, setValue];
};

export default useLocalStorage;