import React, {useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { sendWithAxios } from './functions/api';
import {
    PageSection,
    PageSectionVariants,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Gallery,
    GalleryItem,

    Form,
    FormGroup,
    FormSection,
    FormSelect,
    FormSelectOption,
    Checkbox,

    TextInput,
    ActionGroup,
    Button,
    Alert,

    Grid,
    GridItem, PanelMain, PanelMainBody, Panel,

    SearchInput,
    List, ListItem, SimpleList, SimpleListItem,


} from "@patternfly/react-core";


import '@patternfly/patternfly/patternfly.min.css';


import {Link, useParams} from "react-router-dom";

const TeamsEditPage = () => {
    const navigate = useNavigate();

    let { id } = useParams();
    let itemId = Number(id);


    const [data, setData] = useState({
        'TEAM_USERS' : []
    });


    const [editFromData, setEditFromData] = useState({
        'UF_NAME' : '',
        'TEAM_USERS' : []
    });


    const onTeamFormSubmit = (e) => {
        e.preventDefault();


        sendWithAxios('/teams/' + id + '/', editFromData, 'PUT')
            .then(result => {

                if (itemId === 0) {
                    navigate('/app/teams/' + result.ID)
                } else {

                    setEditFromData({
                        'UF_NAME' : result.UF_NAME,
                        'TEAM_USERS' : result.TEAM_USERS
                    });

                    setData(result);
                }
            });

    };
    const onTeamFormInputChange = e => {
        setEditFromData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const onTeamFormCheckboxChange = e => {

        let { id, checked } = e.target;

        setEditFromData(prevData => {
            let updatedData = {...prevData};
            let userIndex = updatedData.TEAM_USERS.findIndex(user => user.ID === id);

            if(userIndex !== -1) {
                updatedData.TEAM_USERS[userIndex] = {...updatedData.TEAM_USERS[userIndex], checked: checked};
            }
            return updatedData;
        });
    };


    useEffect(() => {

        sendWithAxios('/teams/' + id + '/', {})
            .then(result => {

                setEditFromData({
                    'UF_NAME' : result.UF_NAME,
                    'TEAM_USERS' : result.TEAM_USERS
                });

                setData(result);
            });

    }, [id]);



    return (
        <>


            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Link to={`/app/teams`}>
                            <Button variant="primary">Назад</Button>
                        </Link>
                    </PanelMainBody>
                </PanelMain>
            </Panel>

            <PageSection variant={PageSectionVariants.light}>
                <Grid>
                    <GridItem sm={12} md={8} lg={8}>
                        <Card ouiaId="BasicCard">
                            <CardTitle>{itemId === 0 ? 'Создать новую команду' : 'Редактировать команду'}</CardTitle>
                            <CardBody>
                                <Form onSubmit={onTeamFormSubmit} isHorizontal>
                                    <FormSection title="Основные данные" titleElement="h4">
                                        <FormGroup label="Название" isRequired>
                                            <TextInput
                                                value={editFromData.UF_NAME}
                                                onChange={onTeamFormInputChange}
                                                autoComplete='true'
                                                type="text"
                                                name='UF_NAME'
                                                label="Название"
                                                id='1'
                                                isRequired />
                                        </FormGroup>
                                    </FormSection>

                                    {

                                        itemId === 0 ? '' : (
                                            <FormSection title="Сотрудники" titleElement="h4">
                                                <FormGroup label="Сотрудники">
                                                    {data.TEAM_USERS.map((checkbox) => (

                                                        <Checkbox
                                                            label={checkbox.SHORT_NAME}
                                                            isChecked={checkbox.checked}
                                                            onChange={onTeamFormCheckboxChange}
                                                            isDisabled={checkbox.TEAM_LINK && checkbox.TEAM_LINK.UF_ROLE === 'A'}
                                                            id={checkbox.ID}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormSection>
                                        )

                                    }


                                    <ActionGroup>
                                        <Button
                                            type="submit"
                                            variant="primary">{itemId === 0 ? 'Добавить' : 'Сохранить'}</Button>
                                    </ActionGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </PageSection>
        </>
    );
};

export default TeamsEditPage;