import React, { useContext } from 'react';
import {Link} from 'react-router-dom';

import { UserContext } from './UserContext';
import {
    PageSection,
    PageSectionVariants} from "@patternfly/react-core";



const DashboardPage = () => {
    const { setIsAuthenticated } = useContext(UserContext);

    return (
        <PageSection variant={PageSectionVariants.light}>
            Dashboard
        </PageSection>
    );
};

export default DashboardPage;