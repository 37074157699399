import React, {useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import { sendWithAxios } from './functions/api';
import {
    PageSection,
    PageSectionVariants,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Gallery,
    GalleryItem,
    Title,
    Breadcrumb,
    BreadcrumbItem,

    Form,
    FormGroup,
    TextInput,
    ActionGroup,
    Button,
    Alert,

    Grid,
    GridItem, PanelMain, PanelMainBody, Panel, FormSelect, FormSelectOption

} from "@patternfly/react-core";


import '@patternfly/patternfly/patternfly.min.css';
import {Link} from "react-router-dom";
import {Table, Tbody, Td, Tr} from "@patternfly/react-table";
import {PlusCircleIcon} from "@patternfly/react-icons";

const BoreHolesDetailPage = () => {
    const navigate = useNavigate();

    let { id, subId} = useParams();
    let itemId = Number(id);
    let itemSubId = Number(subId);


    const [data, setData] = useState({
        'GEO_OBJECT' : {},
        'BOREHOLE' : {},
        'LAYERS' : []
    });

    const [isEditMode, setIsEditMode] = useState(false);

    const [editFromData, setEditFromData] = useState({
        'UF_NAME' : '',
        'UF_COORDINATES' : '',
        'UF_COORDINATES_GEODES' : ''
    });

    const onBoreholeFormSubmit = (e) => {
        e.preventDefault();


        sendWithAxios('/geoobjects/' + id + '/borehole/' + subId, editFromData, 'PUT')
            .then(result => {




                if (itemSubId === 0) {

                    if (result.BOREHOLE && result.BOREHOLE.ID) {
                        navigate('/app/geoobjects/' + id + '/borehole/' + result.BOREHOLE.ID)
                    }

                } else {


                    setEditFromData({
                        'UF_NAME' : result.BOREHOLE.UF_NAME,
                        'UF_COORDINATES' : result.BOREHOLE.UF_COORDINATES,
                        'UF_COORDINATES_GEODES' : result.BOREHOLE.UF_COORDINATES_GEODES,
                    });

                    setData(result);
                }
            });

    };

    const onBoreholeFormInputChange = e => {
        setEditFromData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };


    useEffect(() => {

        if (itemSubId === 0) {
            setIsEditMode(true);
        }

        sendWithAxios('/geoobjects/' + id + '/borehole/' + subId, {})
            .then(result => {

                setEditFromData({
                    'UF_NAME' : result.BOREHOLE.UF_NAME,
                    'UF_COORDINATES' : result.BOREHOLE.UF_COORDINATES,
                    'UF_COORDINATES_GEODES' : result.BOREHOLE.UF_COORDINATES_GEODES,
                });

                setData(result);
            });


    }, [id, subId]);

    return (
        <>

            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Link to={`/app/geoobjects/${itemId}`}>
                            <Button variant="primary">Назад</Button>
                        </Link>
                    </PanelMainBody>
                </PanelMain>
            </Panel>

            <PageSection variant={PageSectionVariants.light}>
                <Grid>
                    <GridItem sm={12} md={8} lg={8}>
                        <Title headingLevel="h1">Скважина: {data.BOREHOLE.UF_NAME}</Title>
                        <br/>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/`}>Объекты</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/${itemId}`}>{data.GEO_OBJECT.UF_NAME}</Link>
                            </BreadcrumbItem>
                            {
                                itemSubId === 0 ? (
                                    <BreadcrumbItem>Новая скважина</BreadcrumbItem>
                                ) : (
                                    <BreadcrumbItem>{data.BOREHOLE.UF_NAME}</BreadcrumbItem>
                                )
                            }
                        </Breadcrumb>
                        <br/><br/>


                        {
                            isEditMode === true ? (

                                <>

                                    <Card ouiaId="BasicCard">
                                        <CardTitle>{itemSubId === 0 ? 'Создать новую скважину' : 'Редактировать скважину'}</CardTitle>
                                        <CardBody>
                                            <Form onSubmit={onBoreholeFormSubmit} isHorizontal>


                                                <FormGroup label="Название" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_NAME}
                                                        onChange={onBoreholeFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_NAME'
                                                        isRequired
                                                        name="UF_NAME"/>
                                                </FormGroup>


                                                <FormGroup label="Координаты" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_COORDINATES}
                                                        onChange={onBoreholeFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_COORDINATES'
                                                        isRequired
                                                        name="UF_COORDINATES"/>
                                                </FormGroup>

                                                <FormGroup label="Геодезические координаты" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_COORDINATES_GEODES}
                                                        onChange={onBoreholeFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_COORDINATES_GEODES'
                                                        isRequired
                                                        name="UF_COORDINATES_GEODES"/>
                                                </FormGroup>


                                                <ActionGroup>
                                                    <Button
                                                        type="submit"
                                                        variant="primary">{itemSubId === 0 ? 'Добавить' : 'Сохранить'}</Button>

                                                    {
                                                        itemSubId === 0 ? '' : (
                                                            <Button variant="secondary"
                                                                    onClick={() => setIsEditMode(false)}>
                                                                Отменить
                                                            </Button>
                                                        )
                                                    }

                                                </ActionGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </>

                            ) : (
                                <>

                                    <Button onClick={() => setIsEditMode(true)}>
                                        Редактировать
                                    </Button>
                                    <br/><br/>
                                    <Table variant="compact">
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <b>Название</b>
                                                </Td>
                                                <Td>
                                                    {editFromData.UF_NAME}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Координаты</b>
                                                </Td>
                                                <Td>
                                                    {editFromData.UF_COORDINATES}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Геодезические координаты</b>
                                                </Td>
                                                <Td>
                                                    {editFromData.UF_COORDINATES_GEODES}
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </>
                            )


                        }
                    </GridItem>
                </Grid>
            </PageSection>


            {
                itemSubId === 0 ? '' : (
                    <>
                        <PageSection variant={PageSectionVariants.light}>
                            <Grid>
                                <GridItem sm={12} md={8} lg={8}>
                                    <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}/pipe/0`}>
                                        <Button icon={<PlusCircleIcon/>}>
                                            Добавить обсадную трубу
                                        </Button>
                                    </Link>
                                    <br/><br/>
                                    <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}/aquifers/0`}>
                                        <Button icon={<PlusCircleIcon/>}>
                                            Добавить водоносный горизонт
                                        </Button>
                                    </Link>
                                    <br/>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}/layer/0`}>
                                        <Button icon={<PlusCircleIcon/>}>
                                            Внести слой
                                        </Button>
                                    </Link>
                                </GridItem>
                            </Grid>
                        </PageSection>

                        <br/>


                        <PageSection variant={PageSectionVariants.light}>
                            <Title headingLevel="h3">Слои</Title>
                            <br/>
                            <Gallery hasGutter>
                                {data.LAYERS.map((listItem) => (
                                    <GalleryItem key={listItem.ID}>
                                        <Card ouiaId="BasicCard">
                                            <CardTitle>{listItem.UF_DEPTH_START} - {listItem.UF_DEPTH_END}</CardTitle>
                                            <CardBody></CardBody>
                                            <CardFooter>
                                                <Link to={`/app/geoobjects/${itemId}/borehole/${itemSubId}/layer/${listItem.ID}`}>
                                                    <Button variant="primary">Подробнее</Button>
                                                </Link>
                                            </CardFooter>
                                        </Card>
                                    </GalleryItem>
                                ))}
                            </Gallery>
                        </PageSection>


                    </>
                )
            }

        </>
    );
};

export default BoreHolesDetailPage;