import React, {useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import { sendWithAxios } from './functions/api';
import {
    PageSection,
    PageSectionVariants,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Gallery,
    GalleryItem,

    Form,
    FormGroup,
    TextInput,
    ActionGroup,
    Button,
    Alert,
    AlertGroup,
    FormSelect,
    FormSelectOption,


    Grid,
    GridItem, PanelMain, PanelMainBody, Panel, FormSection, Checkbox, Title, Breadcrumb, BreadcrumbItem

} from "@patternfly/react-core";

import {
    Table,
    Tbody,
    Tr,
    Td,

} from "@patternfly/react-table";

import {
    PlusCircleIcon
} from "@patternfly/react-icons";

import '@patternfly/patternfly/patternfly.min.css';
import {Link} from "react-router-dom";

const GeoObjectsDetailPage = () => {
    const navigate = useNavigate();

    let { id } = useParams();
    let itemId = Number(id);


    const [data, setData] = useState({
        'GEO_OBJECT' : {},
        'TEAMS' : [],
        'BOREHOLES' : [],
        'TEAM' : {}
    });

    const [isEditMode, setIsEditMode] = useState(false);

    const [editFromData, setEditFromData] = useState({
        'UF_NAME' : '',
        'UF_SHORT_NAME' : '',
        'UF_ADDRESS' : '',
        'UF_COORDINATES' : '',
        'UF_TEAM_ID' : 0,
        'UF_CODE' : '',
        'UF_CONTRACT_NUMBER' : ''
    });


    const onObjectFormSubmit = (e) => {
        e.preventDefault();


        sendWithAxios('/geoobjects/' + id + '/', editFromData, 'PUT')
            .then(result => {


                if (itemId === 0 && result.GEO_OBJECT && result.GEO_OBJECT.ID) {
                    navigate('/app/geoobjects/' + result.GEO_OBJECT.ID)
                }


                if (!!result.FORM_ERRORS) {


                } else {

                    setEditFromData({
                        'UF_NAME' : result.GEO_OBJECT.UF_NAME,
                        'UF_SHORT_NAME' : result.GEO_OBJECT.UF_SHORT_NAME,
                        'UF_ADDRESS' : result.GEO_OBJECT.UF_ADDRESS,
                        'UF_COORDINATES' : result.GEO_OBJECT.UF_COORDINATES,
                        'UF_TEAM_ID' : result.GEO_OBJECT.UF_TEAM_ID,
                        'UF_CODE' : result.GEO_OBJECT.UF_CODE,
                        'UF_CONTRACT_NUMBER' : result.GEO_OBJECT.UF_CONTRACT_NUMBER,
                    });
                }

                setData(result);
            });

    };

    const onObjectFormInputChange = e => {
        setEditFromData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };



    useEffect(() => {

        if (itemId === 0) {
            setIsEditMode(true);
        }

        sendWithAxios('/geoobjects/' + id + '/', {})
            .then(result => {

                setEditFromData({
                    'UF_NAME' : result.GEO_OBJECT.UF_NAME,
                    'UF_SHORT_NAME' : result.GEO_OBJECT.UF_SHORT_NAME,
                    'UF_ADDRESS' : result.GEO_OBJECT.UF_ADDRESS,
                    'UF_COORDINATES' : result.GEO_OBJECT.UF_COORDINATES,
                    'UF_TEAM_ID' : result.GEO_OBJECT.UF_TEAM_ID,
                    'UF_CODE' : result.GEO_OBJECT.UF_CODE,
                    'UF_CONTRACT_NUMBER' : result.GEO_OBJECT.UF_CONTRACT_NUMBER,
                });

                setData(result);
            });

    }, [id]);


    return (
        <>
            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Link to={`/app/geoobjects`}>
                            <Button variant="primary">Назад</Button>
                        </Link>
                    </PanelMainBody>
                </PanelMain>
            </Panel>

            <PageSection variant={PageSectionVariants.light}>
                <Grid>
                    <GridItem sm={12} md={8} lg={8}>

                        <Title headingLevel="h1">{itemId === 0 ? "Новый объект" : `Объект: ${data.GEO_OBJECT.UF_NAME}`}</Title>
                        <br/>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/app/geoobjects/`}>Объекты</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                {data.GEO_OBJECT.UF_NAME}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <br/><br/>

                        {
                            isEditMode === true ? (
                                <>

                                    <Card ouiaId="BasicCard">
                                        <CardTitle>{itemId === 0 ? 'Создать новый объект' : 'Редактировать объект'}</CardTitle>
                                        <CardBody>
                                            <Form onSubmit={onObjectFormSubmit} isHorizontal>
                                                {
                                                    data.FORM_ERRORS != null ? (
                                                        <AlertGroup>
                                                            {data.FORM_ERRORS.map((errorItem, index) => (
                                                                <div key={index}>
                                                                    <Alert isInline variant="danger" title={errorItem}></Alert>
                                                                </div>
                                                            ))}
                                                        </AlertGroup>
                                                    ) : ""
                                                }
                                                <FormGroup label="Команда" isRequired>
                                                    <FormSelect
                                                        value={editFromData.UF_TEAM_ID}
                                                        onChange={onObjectFormInputChange}
                                                        name="UF_TEAM_ID"
                                                        aria-label="Команда"
                                                    >
                                                        <FormSelectOption key="0" value="0" label="(Выбрать)"/>
                                                        {data.TEAMS.map((option) => (
                                                            <FormSelectOption key={option.ID} value={option.ID}
                                                                              label={option.UF_NAME}/>
                                                        ))}

                                                    </FormSelect>
                                                </FormGroup>

                                                <FormGroup label="Название" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_NAME}
                                                        onChange={onObjectFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_NAME'
                                                        isRequired
                                                        name="UF_NAME"/>
                                                </FormGroup>

                                                <FormGroup label="Шифр" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_CODE}
                                                        onChange={onObjectFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_CODE'
                                                        isRequired
                                                        name="UF_CODE"/>
                                                </FormGroup>

                                                <FormGroup label="Номер договора" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_CONTRACT_NUMBER}
                                                        onChange={onObjectFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_CONTRACT_NUMBER'
                                                        isRequired
                                                        name="UF_CONTRACT_NUMBER"/>
                                                </FormGroup>

                                                <FormGroup label="Короткое название" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_SHORT_NAME}
                                                        onChange={onObjectFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_SHORT_NAME'
                                                        isRequired
                                                        name="UF_SHORT_NAME"/>
                                                </FormGroup>

                                                <FormGroup label="Адрес" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_ADDRESS}
                                                        onChange={onObjectFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_ADDRESS'
                                                        isRequired
                                                        name="UF_ADDRESS"/>
                                                </FormGroup>

                                                <FormGroup label="Координаты" isRequired>
                                                    <TextInput
                                                        value={editFromData.UF_COORDINATES}
                                                        onChange={onObjectFormInputChange}
                                                        autoComplete='true'
                                                        type="text"
                                                        aria-label='UF_COORDINATES'
                                                        isRequired
                                                        name="UF_COORDINATES"/>
                                                </FormGroup>


                                                <ActionGroup>
                                                    <Button
                                                        type="submit"
                                                        variant="primary">{itemId === 0 ? 'Добавить' : 'Сохранить'}</Button>

                                                    {
                                                        itemId === 0 ? '' : (
                                                            <Button variant="secondary"
                                                                    onClick={() => setIsEditMode(false)}>
                                                                Отменить
                                                            </Button>
                                                        )
                                                    }

                                                </ActionGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </>
                            ) : (
                                <>

                                    <Button onClick={() => setIsEditMode(true)}>
                                        Редактировать
                                    </Button>
                                    <br/><br/>
                                    <Table variant="compact">
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <b>Команда</b>
                                                </Td>
                                                <Td>
                                                    {data.TEAM.UF_NAME}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Название</b>
                                                </Td>
                                                <Td>
                                                    {data.GEO_OBJECT.UF_NAME}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Шифр</b>
                                                </Td>
                                                <Td>
                                                    {data.GEO_OBJECT.UF_CODE}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Номер договора</b>
                                                </Td>
                                                <Td>
                                                    {data.GEO_OBJECT.UF_CONTRACT_NUMBER}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Короткое название</b>
                                                </Td>
                                                <Td>
                                                    {data.GEO_OBJECT.UF_SHORT_NAME}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Адрес</b>
                                                </Td>
                                                <Td>
                                                    {data.GEO_OBJECT.UF_ADDRESS}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>
                                                    <b>Координаты</b>
                                                </Td>
                                                <Td>
                                                    {data.GEO_OBJECT.UF_COORDINATES}
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>


                                </>
                            )
                        }
                    </GridItem>
                </Grid>
            </PageSection>




            {
                itemId === 0 ? '' : (
                    <>
                        <PageSection variant={PageSectionVariants.light}>
                            <Grid>
                                <GridItem sm={12} md={8} lg={8}>
                                    <Link to={`/app/geoobjects/${itemId}/borehole/0`}>
                                        <Button icon={<PlusCircleIcon />}>
                                            Добавить скважину
                                        </Button>
                                    </Link>

                                </GridItem>
                            </Grid>
                        </PageSection>



                        <PageSection variant={PageSectionVariants.light}>
                            <Gallery hasGutter>
                                {data.BOREHOLES.map((listItem) => (
                                    <GalleryItem key={listItem.ID}>
                                        <Card ouiaId="BasicCard">
                                            <CardTitle>{listItem.UF_NAME}</CardTitle>
                                            <CardBody>
                                                {listItem.UF_COORDINATES}
                                            </CardBody>
                                            <CardFooter>
                                                <Link to={`/app/geoobjects/${itemId}/borehole/${listItem.ID}`}>
                                                    <Button variant="primary">Подробнее</Button>
                                                </Link>
                                            </CardFooter>
                                        </Card>
                                    </GalleryItem>
                                ))}
                            </Gallery>
                        </PageSection>




                    </>
                )
            }


        </>
    );
};

export default GeoObjectsDetailPage;