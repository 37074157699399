import React, { useContext, useEffect, useState } from 'react';



import {sendWithAxios} from "./functions/api";
import {UserContext} from "./UserContext";
import {useNavigate} from "react-router-dom";

import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Bullseye,
    Form,
    FormGroup,
    TextInput,
    ActionGroup,
    Button,
    Alert

} from '@patternfly/react-core';



const AuthForm = () => {
    const { isAuthenticated, setIsAuthenticated } = useContext(UserContext);
    const navigate = useNavigate();

    const [strError, setStrError] = useState('');
    const [userLogin, setUserLogin] = useState('');
    const [userPassword, setUserPassword] = useState('');


    const login = (e) => {
        e.preventDefault();

        const formData = {
            userLogin,
            userPassword,
        };

        sendWithAxios('/user/auth/', formData, 'POST')
            .then(result => {
                if (result.success && result.jwtToken) {
                    window.localStorage.setItem('userJwtToken', result.jwtToken);
                    setIsAuthenticated(true);
                    navigate('/app/dashboard');
                } else {
                    setStrError(result.errors);
                    window.localStorage.removeItem('userJwtToken');
                }
            }
        );
    };


    return (
        <Bullseye>
            <Card ouiaId="BasicCard">
                <CardHeader>
                    <CardTitle>
                        Авторизация
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {strError ? [<Alert key={1} variant="danger" isInline isPlain title={strError} />, <br key={2} />] : null}
                    <Form onSubmit={login}>
                        <FormGroup>
                            <TextInput
                                value={userLogin}
                                onChange={(event) => setUserLogin(event.target.value)}
                                autoComplete='true'
                                type="text"
                                aria-label='userLogin'
                                placeholder="Логин"
                                isRequired
                                name="userLogin" />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                value={userPassword}
                                onChange={(event) => setUserPassword(event.target.value)}
                                autoComplete='true'
                                type="password"
                                aria-label='userPassword'
                                placeholder="Пароль"
                                isRequired
                                name="userPassword" />
                        </FormGroup>
                        <ActionGroup>
                            <Button
                                type="submit"
                                variant="primary">Войти</Button>
                        </ActionGroup>
                    </Form>
                </CardBody>
            </Card>
        </Bullseye>
    );
};


export default AuthForm;