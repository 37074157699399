import React, { useState } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';



import { UserContext } from './pages/UserContext';

import useLocalStorage from './pages/functions/useLocalStorage';


import TeamsPage from "./pages/TeamsPage";
import GeoObjectsPage from "./pages/GeoObjectsPage";
import GeoObjectsDetailPage from "./pages/GeoObjectsDetailPage";
import BoreHolesDetailPage from "./pages/BoreHolesDetailPage";
import TeamsEditPage from "./pages/TeamsEditPage";
import LayersDetailPage from "./pages/LayersDetailPage";
import SampleDetailPage from "./pages/SampleDetailPage";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useLocalStorage('isAuthenticated', false);


    return (
        <UserContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />}>
                        <Route path="/app/dashboard" element={<DashboardPage />}></Route>

                        <Route path="/app/teams" element={<TeamsPage />}></Route>
                        <Route path="/app/teams/:id" element={<TeamsEditPage />}></Route>

                        <Route path="/app/geoobjects" element={<GeoObjectsPage />}></Route>
                        <Route path="/app/geoobjects/:id" element={<GeoObjectsDetailPage />}></Route>

                        <Route path="/app/geoobjects/:id/borehole/:subId" element={<BoreHolesDetailPage />}></Route>
                        <Route path="/app/geoobjects/:id/borehole/:subId/layer/:layerId" element={<LayersDetailPage />}></Route>
                        <Route path="/app/geoobjects/:id/borehole/:subId/layer/:layerId/sample/:sampleId" element={<SampleDetailPage />}></Route>


                        <Route
                            path="*"
                            element={
                                (<h4>Page not found</h4>)
                            }
                        />
                    </Route>
                </Routes>
            </Router>
        </UserContext.Provider>
    );
};

export default App;