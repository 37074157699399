
import axios from 'axios';

export async function sendWithAxios(endpoint, data, requestMethod = 'GET') {
    try {

        const headers = {
            'Authorization': window.localStorage.getItem('userJwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        console.log(headers);

        let response;

        switch(requestMethod) {

            case 'GET':
                response = await axios.get( process.env.REACT_APP_API_URL + `${endpoint}`, { headers });
                break;
            case 'POST':
                response = await axios.post( process.env.REACT_APP_API_URL + `${endpoint}`, data,{ headers });
                break;
            case 'PUT':
                response = await axios.put( process.env.REACT_APP_API_URL + `${endpoint}`, data,{ headers });
                break;
        }

        return response.data.result;

    } catch (error) {
        console.error(`Error during API call: ${error}`);
        throw error;
    }
}