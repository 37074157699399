import React, {useEffect, useState } from 'react';


import { sendWithAxios } from './functions/api';
import {
    PageSection,
    PageSectionVariants,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Gallery,
    GalleryItem,

    Form,
    FormGroup,
    TextInput,
    ActionGroup,
    Button,
    Alert,

    Grid,
    GridItem,

    Panel,
    PanelMain,
    PanelMainBody

} from "@patternfly/react-core";


import '@patternfly/patternfly/patternfly.min.css';
import {Link} from "react-router-dom";

const TeamsPage = () => {

    const [data, setData] = useState({
        items : []
    });



    useEffect(() => {

        sendWithAxios('/teams/', {})
            .then(result => {
                setData(result);
            });


    }, []);


    return (
        <>

            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Link to={`/app/teams/0`}>
                            <Button variant="primary">Создать новую команду</Button>
                        </Link>
                    </PanelMainBody>
                </PanelMain>
            </Panel>

            <PageSection variant={PageSectionVariants.light}>
                <Gallery hasGutter>
                    {data.items.map((listItem) => (
                        <GalleryItem key={listItem.ID}>
                            <Card ouiaId="BasicCard">
                                <CardTitle>{listItem.UF_NAME}</CardTitle>
                                <CardBody>
                                    Id: {listItem.ID}
                                </CardBody>
                                <CardFooter>
                                    <Link to={`/app/teams/${listItem.ID}`}>
                                        <Button variant="primary">Подробнее</Button>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </GalleryItem>
                    ))}
                </Gallery>
            </PageSection>
        </>
    );
};

export default TeamsPage;